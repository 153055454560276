<template>
    <div>
        <b-button-toolbar class="mb-3">
            <b-button-group class="ml-auto">
                <Button to="../list">
                    <b-icon icon="list" />
                    Список
                </Button>
                <Button to="view">
                    <b-icon icon="info" />
                    Просмотреть
                </Button>
                <Button to="../add">
                    <b-icon icon="plus" />
                    Добавить
                </Button>
                <poster
                    v-if="$auth.user().type === UserTypeEnum.admin || (item && item.type !== UserTypeEnum.admin)"
                    tag="b-button"
                    :item-id="$route.params.id"
                    method="admin.users.delete"
                    :disabled="!item || !!item.deletedAt"
                    success-message="Данные удалены"
                    @complete="onDeleted"
                >
                    <b-icon icon="trash" />
                    Удалить
                </poster>
                <poster
                    v-if="item && item.active && ($auth.user().type === UserTypeEnum.admin || item.type !== UserTypeEnum.admin)"
                    tag="b-button"
                    :item-id="item.id"
                    method="admin.users.deactivate"
                    @complete="refreshData"
                >
                    <b-icon icon="pause" />
                    Деактивировать
                </poster>
                <poster
                    v-if="item && !item.active && ($auth.user().type === UserTypeEnum.admin || item.type !== UserTypeEnum.admin)"
                    tag="b-button"
                    :item-id="item.id"
                    method="admin.users.activate"
                    @complete="refreshData"
                >
                    <b-icon icon="play" />
                    Активировать
                </poster>
            </b-button-group>
        </b-button-toolbar>
        <b-overlay :show="(!item && !stages) || request">
            <b-form
                autocomplete="off"
                @submit="submit"
            >
                <b-form-group
                    label="Email"
                    label-for="email"
                >
                    <b-form-input
                        id="email"
                        v-model="form.email"
                        type="text"
                        placeholder="Введите email"
                        required
                        :state="errors.email ? false : null"
                        autocomplete="off"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.email"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="Сменить пароль?">
                    <b-form-checkbox v-model="form.change_password" />
                </b-form-group>
                <b-form-group
                    v-if="form.change_password"
                    label="Пароль"
                    label-for="password"
                >
                    <b-form-input
                        id="password"
                        v-model="form.password"
                        type="password"
                        placeholder="Введите пароль"
                        :state="errors.password ? false : null"
                        autocomplete="new-password"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.password"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="ФИО"
                    label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="form.name"
                        type="text"
                        placeholder="Введите ФИО"
                        required
                        :state="errors.name ? false : null"
                        autocomplete="off"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.name"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="Аккаунт коллектора"
                    label-for="telegram_username"
                >
                    <b-form-input
                        id="telegram_username"
                        v-model="form.telegram_username"
                        type="text"
                        placeholder="Введите telegram"
                        :state="errors.telegram_username ? false : null"
                        autocomplete="off"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.telegram_username"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="Тип"
                    label-for="type"
                >
                    <b-form-select
                        id="type"
                        v-model="form.type"
                        :options="types"
                        placeholder="Укажите тип"
                        required
                        :state="errors.type ? false : null"
                        autocomplete="off"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.type"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="Стадия"
                    label-for="stage_id"
                >
                    <b-form-select
                        id="stage_id"
                        v-model="form.stage_id"
                        :options="stages"
                        placeholder="Укажите стадию"
                        required
                        :state="errors.stage_id ? false : null"
                        autocomplete="off"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.stage_id"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="Вес"
                    label-for="weight"
                >
                    <b-form-input
                        id="weight"
                        v-model="form.weight"
                        type="text"
                        placeholder="Введите вес"
                        required
                        :state="errors.weight ? false : null"
                        autocomplete="off"
                        :number="true"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.weight"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>

                <Button
                    type="submit"
                    variant="primary"
                >
                    Сохранить
                </Button>
            </b-form>
        </b-overlay>
    </div>
</template>

<script>
import UserTypeEnum from "../../../enums/UserTypeEnum";

export default {
    data() {
        return {
            item: null,
            form: {
                id: null,
                email: null,
                change_password: false,
                telegram_username: null,
                password: null,
                name: null,
                type: null,
                stage_id: null,
                weight: null,
            },
            stages: null,
            errors: {},
            request: false,
            UserTypeEnum,
        }
    },
    computed: {
        types() {
            // eslint-disable-next-line no-undef
            return Object.keys(UserTypeEnum).map((key) => {
                return {value: key, text: UserTypeEnum[key]}
            });
        },
    },
    mounted() {
        this.refreshData();
    },
    methods: {
        submit(event) {
            event.preventDefault();

            if (this.request) {
                return;
            }

            this.request = true;
            this
                .jsonRpc("admin.users.edit", {...this.form})
                .then(async () => {
                    this.$alertify.success("Данные успешно изменены");
                    this.$router.push({path: "view"});
                })
                .catch(async ({error}) => {
                    if (this.$formValidation.isValidationRpcResponse(error)) {
                        this.errors = this.$formValidation.parse(error.data);
                    }
                })
                .finally(async () => {
                    this.request = false;
                });

        },
        onDeleted() {
            this.$router.push("../list")
        },
        refreshData() {
            const id = parseInt(this.$route.params.id);

            this.jsonRpc("admin.users.get", {id}).then(async (response) => {
                this.item = response;
                for (let i in this.item) {
                    if(i === "telegramUsername"){
                        this.form["telegram_username"] = this.item[i]
                    }
                    // eslint-disable-next-line no-prototype-builtins
                    if (this.form.hasOwnProperty(i) && this.item.hasOwnProperty(i)) {
                        // noinspection JSUnfilteredForInLoop
                        this.form[i] = this.item[i];
                    }
                }
                this.form.stage_id = this.item.stage ? this.item.stage.id : 0;
            })
            this.jsonRpc("admin.stages.fullList").then(async (response) => {
                this.stages = response.map((stage) => {
                    return {value: stage.id, text: stage.name};
                });
                this.stages.unshift({value: 0, text: "Не выбрана"});
            });
        },
    },
}
</script>
